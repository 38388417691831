import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { useInView } from "react-intersection-observer";
import { Element } from "react-scroll";
import PageContext from "@PageContext";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import ConsentData from "@micado-digital/react-ui/components/ConsentData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import useScrollToLocation from "../hooks/useScrollToLocation";
import Locals from "@micado-digital/react-ui/components/Locals";
import Animation from "@Components/views/Animation";
import Header from "@Components/views/Header";
import StickyNav from "@Components/views/StickyNav";
import AnchorMenu from "@Components/views/AnchorMenu";
import Default from "./Default";
import Room from "./Room";
import Core from "./Core";

import styles from "./Views.styles";

const Footer = loadable(() => import("@Components/views/Footer"));

const Views = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { authorID, elements, id, lang, layoutTag, layoutVariant } = pageData;
	const { ref, inView } = useInView();
	const css = styles();

	useScrollToLocation(160);

	const anchorItems =
		elements?.filter(({ anchor }) => anchor)?.map(item => item?.anchor) ?? [];

	return (
		<WebProfile key={lang}>
			<ConsentData lang={lang}>
				<AccountData authorID={authorID}>
					<Locals url={`/locals/${lang || "de"}.json`}>
						<div className={clsx(css.page, "mco-page", `mco-page-${layoutTag}`)}>
							<Header layoutTag={layoutTag} />
							{anchorItems &&
								anchorItems?.length > 0 &&
								layoutVariant !== "tourism-room-detail" && <AnchorMenu items={anchorItems} />}
							{layoutTag === "standard" && layoutVariant !== "no-animation" && <Animation />}
							<PageTransition color="rgba(0, 0, 0, 0.5)" position="top" />
							{layoutTag === "standard" && (
								<Element name="scroll-content">
									<Default lang={lang} />
								</Element>
							)}
							{layoutTag === "tourism-room-detail" && (
								<Room animationMediaFormats={{ xs: "provider" }} />
							)}
							<div ref={ref}>
								<Footer />
							</div>
							{!inView && layoutTag !== "tourism-room-detail" && <StickyNav />}
							<micado-ial language={lang} path={REACT_APP_PATH} page-id={id}></micado-ial>
						</div>
						<Core />
					</Locals>
				</AccountData>
			</ConsentData>
		</WebProfile>
	);
};

export default Views;
