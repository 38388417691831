import React from "react";
import Elements from "@Components/Elements";

const Default = () => {
	return (
		<>
			<Elements />
		</>
	);
};

export default Default;
