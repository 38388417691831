import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { useScrollYPosition } from "react-use-scroll-position";
import useTheme from "@material-ui/core/styles/useTheme";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./Header.styles";

const Container = loadable(() => import("../Container"));
const Hamburger = loadable(() => import("./Hamburger"));
// const Lang = loadable(() => import("./Lang"));
const Logo = loadable(() => import("./Logo"));
const GalleryLink = loadable(() => import("./GalleryLink"));
const Phone = loadable(() => import("./Phone"));
const MegaMenu = loadable(() => import("./Menu/MegaMenu"));

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [stickyHeader, setStickyHeader] = useState(false);
	const [pageData] = useContext(PageContext);
	const [ui] = useContext(UiContext);
	const { _loading } = ui;
	const { layoutVariant } = pageData;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	const theme = useTheme();
	const css = styles(theme);
	const scrollY = useScrollYPosition();

	useEffect(() => {
		if (!_loading) {
			setMenuOpen(false);
		}
	}, [_loading]);

	useEffect(() => {
		if (scrollY > 90) {
			setStickyHeader(true);
		} else {
			setStickyHeader(false);
		}
	}, [scrollY]);

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.stickyHeader]: stickyHeader || layoutVariant !== "fullscreen",
				[css.menuActive]: menuOpen,
				[css.headerFullscreen]: layoutVariant === "fullscreen"
			})}
		>
			<Container
				className={clsx(css.container, "mco-view-component-header__container")}
				maxWidth={false}
			>
				<Hamburger
					menuOpen={menuOpen}
					setMenuOpen={setMenuOpen}
					sticky={(stickyHeader && !menuOpen) || (layoutVariant !== "fullscreen" && !menuOpen)}
				/>
				{!menuOpen && isMobile && <Logo sticky={stickyHeader} layoutVariant={layoutVariant} />}
				{isDesktop && (
					<Logo
						menuOpen={menuOpen}
						sticky={stickyHeader}
						noAnimation={layoutVariant !== "fullscreen"}
					/>
				)}
				{isDesktop && (
					<GalleryLink
						sticky={
							(stickyHeader && !menuOpen) || (layoutVariant !== "fullscreen" && !menuOpen)
						}
					/>
				)}
				<Phone
					sticky={
						(stickyHeader && !menuOpen) ||
						(layoutVariant !== "fullscreen" && !menuOpen) ||
						isMobile
					}
				/>
				{/* {(menuOpen || isDesktop) && (
					<Location
						sticky={
							(stickyHeader && !menuOpen) || (layoutVariant !== "fullscreen" && !menuOpen)
						}
					/>
				)} */}
				{/* <Lang
					sticky={(stickyHeader && !menuOpen) || (layoutVariant !== "fullscreen" && !menuOpen)}
				/> */}
			</Container>
			{menuOpen && <MegaMenu />}
		</div>
	);
};

export default Header;
