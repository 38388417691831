import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(10),
		margin: "0 auto",
		width: "100%",
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(7)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(7)
		},
		"& .mco-product-detail-title + .mco-product-detail-facts": {
			marginTop: -theme.spacing(7)
		},
		[theme.breakpoints.up("md")]: {
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(14)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10.5)
			}
		}
	},
	facts: {
		background: "#F8F8F7 !important",
		padding: theme.spacing(7, 0) + " !important",
		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(10, 0) + " !important"
		}
	},
	buttonWrapper: {
		display: "flex !important",
		justifyContent: "center !important",
		marginLeft: "auto !important",
		marginRight: "auto !important"
	}
}));

export default styles;
