import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	container: props => ({
		display: "block",
		margin: "0 auto",
		maxWidth:
			props?.maxWidth === "md"
				? theme.breakpoints.values.md
				: props?.maxWidth === "lg"
				? theme.breakpoints.values.lg
				: props?.maxWidth === "xl"
				? theme.breakpoints.values.xl
				: props?.maxWidth === false
				? "none"
				: "100%",
		padding: props?.maxWidth === false ? 0 : theme.spacing(0, 2),
		scrollMarginTop: "160px",
		width: "100%"
	})
}));

export default styles;
