import React, { useContext, useEffect, useState } from "react";
import { scroller } from "react-scroll";
import clsx from "clsx";
import vhCheck from "vh-check";
import PageContext from "@PageContext";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Animation.styles";

const Animation = () => {
	const css = styles();
	const { REACT_APP_PATH } = process.env;
	const [textAnimation, setTextAnimation] = useState(false);
	const [pageData] = useContext(PageContext);
	const { animationID, layoutVariant } = pageData;
	vhCheck({
		bind: true
	});

	useEffect(() => {
		setTimeout(() => {
			setTextAnimation(true);
		}, 1000);
	}, []);

	const handleScroll = (name, duration = 500) => {
		scroller.scrollTo(name, {
			duration: duration,
			offset: -90,
			smooth: "easeOutCubic"
		});
	};

	return (
		<div
			className={clsx(css.animation, "mco-view-component-animation", {
				[css.fullscreen]: layoutVariant === "fullscreen",
				"mco-view-component-animation--text-visible": textAnimation
			})}
		>
			<ReactAnimation
				api={`${REACT_APP_PATH}/animationV3.json.api`}
				showBullets={true}
				showArrows={true}
				prevIcon={<ReactSVGIcon src="/img/icons/arrow-left.svg" size={48} />}
				nextIcon={<ReactSVGIcon src="/img/icons/arrow-right.svg" size={48} />}
				id={animationID}
				mediaFormats={{
					xs:
						layoutVariant === "fullscreen"
							? "animation-fullscreen-mobile"
							: "animation-mobile",
					sm: layoutVariant === "fullscreen" ? "animation-fullscreen-hd" : "animation-hd",
					xl: layoutVariant === "fullscreen" ? "animation-fullscreen" : "animation"
				}}
				webpFallback
			/>
			<div
				className={css.scrollDown}
				onClick={() => {
					handleScroll("scroll-content");
				}}
			>
				<span>Scrollen</span>
				<ReactSVGIcon
					color="initial"
					size={20}
					src="/img/icons/chevron-down.svg"
					aria-label="scroll-down"
				/>
			</div>
		</div>
	);
};

export default Animation;
