import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	"@keyframes increase": {
		"100%": {
			height: 1000
		}
	},
	animation: {
		height: "50vh",
		minHeight: 300,
		position: "relative",
		"@media (min-width: 960px) and (max-height: 800px)": {
			minHeight: 450
		},
		"@media (min-width: 1280px) and (max-height: 800px)": {
			minHeight: 512
		},
		"& .mco-animation-01__texts": {
			background: "#FAFAF9",
			bottom: 115,
			display: "flex",
			flexDirection: "column",
			margin: "0 32px",
			maxWidth: 460,
			opacity: 0,
			padding: theme.spacing(3),
			position: "absolute",
			transition: "opacity 1500ms ease",
			[theme.breakpoints.up("md")]: {
				padding: theme.spacing(5),
				right: 0
			},
			"@media (min-width: 1328px)": {
				left: "50%",
				right: "auto",
				marginLeft: "164px"
			},
			"& .mco-animation-01__text--1": {
				color: "#333333",
				fontSize: "clamp(32px, 8vw, 48px)",
				fontWeight: 300,
				lineHeight: 1.125,
				position: "relative",
				textAlign: "left"
			},
			"& .mco-animation-01__text--2": {
				color: theme.palette.secondary.dark,
				fontSize: 18,
				letterSpacing: "0.4px",
				marginTop: theme.spacing(2),
				"& a": {
					alignItems: "center",
					color: theme.palette.secondary.dark,
					display: "flex",
					gap: theme.spacing(1),
					justifyContent: "flex-end",
					position: "relative",
					"&::after": {
						backgroundImage: "url('/img/icons/chevron-right.svg')",
						content: '""',
						display: "flex",
						height: 24,
						position: "relative",
						width: 24
					}
				}
			}
		},
		"&.mco-view-component-animation--text-visible": {
			"& .mco-animation-01__texts": {
				opacity: 1
			}
		},
		"& .mco-animation-01__arrow-next, .mco-animation-01__arrow-prev": {
			background: "rgba(0, 0, 0, 0.2)",
			height: 56,
			[theme.breakpoints.up("lg")]: {
				height: 82
			}
		},
		"& .mco-animation-01__arrow-next": {
			right: 0
		},
		"& .mco-animation-01__arrow-prev": {
			left: 0
		},
		"& .mco-animation-01__arrow-next svg, .mco-animation-01__arrow-prev svg": {
			[theme.breakpoints.up("lg")]: {
				width: "75px !important"
			}
		},
		"& .mco-animation-01__bullets": {
			bottom: theme.spacing(10),
			justifyContent: "center",
			[theme.breakpoints.up("md")]: {
				bottom: theme.spacing(5)
			},
			"& .mco-animation-01__bullet": {
				border: "1px solid #FFFFFF",
				background: "none",
				opacity: 1,
				height: 8,
				marginLeft: 8,
				marginRight: 8,
				width: 8
			},
			"& .mco-animation-01__bullet--selected": {
				background: "#FFFFFF",
				opacity: 1
			}
		},
		[theme.breakpoints.up("md")]: {
			margin: "32px 32px 0"
		},
		[theme.breakpoints.up(1440)]: {
			margin: "70px 170px 0"
		},
		[theme.breakpoints.up("xl")]: {
			margin: "70px auto 0",
			maxWidth: 1580,
			width: "100%"
		}
	},
	fullscreen: {
		height: "calc(var(--vh, 1vh) * 100 - 90px)",
		"& .mco-animation-01__slider:before": {
			background: "rgba(0, 0, 0, 0.15)",
			content: "''",
			height: "100%",
			left: 0,
			position: "absolute",
			top: 0,
			width: "100%",
			zIndex: 2
		},
		"& .mco-animation-01__slider:after": {
			background: "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)",
			content: "''",
			height: "20vh",
			left: 0,
			position: "absolute",
			top: 0,
			width: "100%",
			zIndex: 2
		},
		[theme.breakpoints.up("md")]: {
			display: "flex",
			flexDirection: "column",
			height: "calc(var(--vh, 1vh) * 100)",
			marginLeft: "0 !important",
			marginRight: "0 !important",
			marginTop: "-90px !important",
			maxHeight: "unset",
			maxWidth: "none !important",
			"& .mco-animation-01__slide-item": {
				zIndex: "unset"
			}
		}
	},
	scrollDown: {
		bottom: theme.spacing(11.5),
		cursor: "pointer",
		color: "#FFFFFF",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: theme.spacing(2),
		left: theme.spacing(1),
		position: "absolute",
		zIndex: 10,
		"& span": {
			display: "flex",
			fontSize: 10,
			fontWeight: 600,
			letterSpacing: "0.4px",
			lineHeight: 1,
			transform: "rotate(-90deg)",
			[theme.breakpoints.up("md")]: {
				fontSize: 14
			}
		},
		[theme.breakpoints.up("md")]: {
			bottom: theme.spacing(2.5),
			gap: theme.spacing(3)
		}
	}
}));

export default styles;
