import React from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useSystemLinksExt from "@micado-digital/react-ui/hooks/useSystemLinksExt";

import styles from "./StickyNav.styles";

const Divider = loadable(() => import("@material-ui/core/Divider"));
const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const StickyNav = () => {
	const sysLink = useSystemLinksExt();
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = styles();

	return (
		<div
			className={clsx("mco-sticky-nav", css.root, { "mco-sticky-nav--desktop": isDesktop })}
		>
			{sysLink("booking")?.identifier && (
				<div className={clsx("mco-sticky-nav__booking", css.item)}>
					<ReactLink to={sysLink("booking")?.url} target="_blank" rel="noreferrer">
						<ReactSVGIcon src="/img/icons/calendar.svg" size={24} />
						<span className={css.label}>{sysLink("booking")?.title}</span>
					</ReactLink>
				</div>
			)}
			{sysLink("enquiry")?.identifier && (
				<>
					<Divider orientation="vertical" flexItem />
					<div className={clsx("mco-sticky-nav__enquiry", css.item)}>
						<ReactLink to={sysLink("enquiry")?.url}>
							<ReactSVGIcon src="/img/icons/request.svg" size={24} />
							<span className={css.label}>{sysLink("enquiry")?.title}</span>
						</ReactLink>
					</div>
				</>
			)}
			{sysLink("offers")?.identifier && (
				<>
					<Divider orientation="vertical" flexItem />
					<div className={clsx("mco-sticky-nav__offers", css.item)}>
						<ReactLink to={sysLink("offers")?.url}>
							<ReactSVGIcon src="/img/icons/offers.svg" size={24} />
							<span className={css.label}>{sysLink("offers")?.title}</span>
						</ReactLink>
					</div>
				</>
			)}
			{sysLink("gallery")?.identifier && !isDesktop && (
				<>
					<Divider orientation="vertical" flexItem />
					<div className={clsx("mco-sticky-nav__offers", css.item)}>
						<ReactLink to={sysLink("gallery")?.url}>
							<ReactSVGIcon src="/img/icons/gallery.svg" size={24} />
							<span className={css.label}>{sysLink("gallery")?.title}</span>
						</ReactLink>
					</div>
				</>
			)}
		</div>
	);
};

export default StickyNav;
