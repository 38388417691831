export const theme = {
	typography: {
		fontFamily: "Josefin Sans",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		h1: {
			fontSize: "clamp(34px, 8vw, 48px)",
			fontWeight: 400,
			letterSpacing: "0.1em",
			lineHeight: 1.3,
			textAlign: "center",
			textTransform: "uppercase"
		},
		h2: {
			fontSize: "clamp(30px, 8vw, 40px)",
			fontWeight: 400,
			letterSpacing: "0.1em",
			lineHeight: 1.3,
			textAlign: "center",
			textTransform: "uppercase"
		},
		h3: {
			fontSize: "clamp(26px, 8vw, 34px)",
			fontWeight: 400,
			letterSpacing: "0.1em",
			lineHeight: 1.3,
			textAlign: "center",
			textTransform: "uppercase"
		},
		h4: {
			fontSize: "clamp(24px, 8vw, 30px)",
			fontWeight: 300,
			letterSpacing: "0.1em",
			lineHeight: 1.3,
			textTransform: "uppercase"
		},
		h5: {
			fontSize: 18,
			fontWeight: 400,
			letterSpacing: "0.05em",
			lineHeight: 1.3,
			textTransform: "uppercase"
		},
		h6: {
			fontSize: 24,
			fontWeight: 300,
			letterSpacing: "0.05em",
			lineHeight: 1.3
		},
		body1: {
			fontSize: 20,
			fontWeight: 300,
			lineHeight: 1.6
		},
		body2: {
			fontWeight: 300,
			fontSize: 18,
			lineHeight: 1.6
		},
		subtitle1: {
			fontWeight: 400,
			fontSize: 20,
			lineHeight: 1.6
		},
		subtitle2: {
			fontWeight: 400,
			fontSize: 18,
			lineHeight: 1.6
		},
		overline: {
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: "0.01em",
			lineHeight: 1.3
		},
		caption: {
			fontWeight: 400,
			fontSize: 10,
			letterSpacing: "0.4px",
			lineHeight: 1.3
		},
		button: {
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: "0.4px",
			lineHeight: 1,
			textTransform: "unset"
		}
	},
	palette: {
		primary: {
			// light: "#BDA8BB",
			// main: "#7F536C",
			// dark: "#573F52"
			light: "#beb7ae",
			main: "#938E87",
			dark: "#5f5b57"
		},
		secondary: {
			light: "#C9CDA2",
			main: "#AFB474",
			dark: "#7C8052",
			contrastText: "#2D2F1E"
		},
		error: {
			light: "#DB9494",
			main: "#A83838",
			dark: "#672222"
		},
		warning: {
			light: "#EAD28A",
			main: "#DAAE2B",
			dark: "#8B6E18"
		},
		info: {
			light: "#7DB4D4",
			main: "#2E688A",
			dark: "#1B3D50"
		},
		success: {
			light: "#B0D279",
			main: "#678A2E",
			dark: "#41581D"
		},
		grey: {
			50: "#F8F8F7",
			100: "#E7E6E4",
			200: "#D3D2CF",
			300: "#B6B4AF",
			400: "#9E9B94",
			500: "#868279",
			600: "#6B6861",
			700: "#504E49",
			800: "#363430",
			900: "#1B1A18",
			A100: "rgba(0, 0, 0, 0.1)",
			A200: "rgba(0, 0, 0, 0.2)",
			A400: "rgba(0, 0, 0, 0.5)",
			A700: "rgba(0, 0, 0, 0.8)"
		},
		text: {
			primary: "#333333",
			secondary: "#666666",
			disabled: "#BFBFBF"
		},
		divider: "#CCCCCC",
		background: {
			backdrop: "rgba(0, 0, 0, 0.5)",
			body: "#D3D2CF",
			header: "#FFFFFF",
			menu: "#5f5b57",
			footer: "#5f5b57",
			teaser: "#F0EEE9",
			form: "#FAFAF9"
		},
		brand: {
			brand1: "#464547",
			brand2: "#938E87",
			brand3: "#F0EEE9"
		},
		shadows: {
			shadow1: "0px 1px 2px 0px rgba(0, 0, 0, 0.24)",
			shadow2: "0px 5px 10px rgba(0, 0, 0, 0.12)",
			shadow3: "0px 12px 24px 0px rgba(0, 0, 0, 0.12)"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				height: "48px !important",
				textTransform: "unset !important"
			},
			sizeSmall: {
				fontSize: "14px !important",
				fontWeight: "600 !important",
				height: "48px !important"
			},
			sizeLarge: {
				fontSize: "20px !important",
				fontWeight: "600 !important",
				height: "60px !important"
			},
			outlined: {}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	customTypography: {
		menu: {
			mobile: {
				level1: {
					fontWeight: 400,
					fontSize: 22,
					lineHeight: 1.3
				},
				level2: {
					fontWeight: 300,
					fontSize: 18,
					lineHeight: 1.3
				}
			},
			desktop: {
				level1: {
					fontWeight: 400,
					fontSize: 22,
					lineHeight: 1.3
				},
				level2: {
					fontWeight: 300,
					fontSize: 18,
					lineHeight: 1.3
				}
			}
		},
		footer: {
			footermenu: {
				level1: {
					fontWeight: 600,
					fontSize: 16,
					lineHeight: 1.3
				},
				level2: {
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.3
				}
			},
			metamenu: {
				fontWeight: 400,
				fontSize: 12,
				lineHeight: 1.3
			}
		}
	}
};
