import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	breadcrumb: {
		fontSize: 14,
		marginTop: theme.spacing(3) + "px !important",
		padding: theme.spacing(1, 0),
		"& .mco-breadcrumb": {
			gap: theme.spacing(4),
			justifyContent: "center"
		},
		"& .mco-breadcrumb-item": {
			position: "relative",
			"&:first-child .mco-breadcrumb-item__label": {
				display: "none"
			}
		},
		"& .mco-breadcrumb-item:not(:last-child):after": {
			background: "#CCCCCC",
			content: "''",
			height: 16,
			marginTop: -8,
			right: -16,
			position: "absolute",
			top: "50%",
			width: 1
		},
		"& .mco-breadcrumb-item__page .mco-breadcrumb-item__label": {
			color: theme.palette.primary.dark,
			opacity: 1
		},
		"& .mco-breadcrumb-item:last-child a": {
			color: theme.palette.primary.dark,
			fontWeight: theme.typography.fontWeightBold
		},
		"& .mco-breadcrumb-item__link": {
			color: theme.palette.text.secondary,
			gap: theme.spacing(4)
		},
		"& .mco-breadcrumb-item__link:hover": {
			color: theme.palette.primary.main + " !important"
		},
		"& .mco-breadcrumb-item__label": {
			color: theme.palette.text.secondary,
			fontSize: 14,
			fontFamily: "Josefin Sans",
			fontWeight: 400,
			lineHeight: "14px"
		},
		"& .mco-breadcrumb-item__icon": {
			marginTop: -2,
			position: "relative",
			"&:after": {
				background: "#CCCCCC",
				content: "''",
				height: 16,
				marginTop: -8,
				right: -16,
				position: "absolute",
				top: "50%",
				width: 1
			}
		}
	},
	breadcrumbNoAnimation: {
		marginTop: theme.spacing(3)
	}
}));

export default styles;
