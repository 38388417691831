import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		background: "#A5744A",
		display: "flex",
		gap: theme.spacing(1.5),
		height: 64,
		justifyContent: "center",
		padding: theme.spacing(1.5, 2),
		width: "100%",
		zIndex: 10,
		[theme.breakpoints.up("md")]: {
			"& .MuiDivider-root": {
				background: "rgba(255,255,255,0.5)"
			},
			"& > div > a": {
				display: "flex",
				flexDirection: "row",
				gap: 12,
				width: "auto",
				"& > span": {
					fontSize: 18,
					fontWeight: 400,
					letterSpacing: "0.4px"
				}
			}
		}
	},
	rootDesktop: {
		width: "auto"
	},
	item: {
		padding: theme.spacing(0, 1),
		"& a": {
			alignItems: "center",
			color: "#FFFFFF",
			cursor: "pointer",
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(0.75),
			height: "100%",
			justifyContent: "center",
			transition: theme.transitions.create(["background"], {
				duration: theme.transitions.duration.short
			}),
			width: 64,
			[theme.breakpoints.up("md")]: {
				"&:hover": {
					opacity: 0.8
				}
			}
		}
	},
	label: {
		fontSize: 14,
		fontWeight: 600,
		letterSpacing: "0.4px",
		lineHeight: 1
	}
}));

export default styles;
