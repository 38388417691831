import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		"& > .mco-sticky-nav": {
			bottom: 0,
			left: 0,
			position: "fixed",
			width: "100%",
			[theme.breakpoints.up("md")]: {
				height: 48,
				left: "auto",
				maxWidth: 447,
				width: "auto",
				right: 0
			},
			[theme.breakpoints.up("lg")]: {
				left: "50%",
				right: "auto",
				marginLeft: 178
			}
		}
	}
}));

export default styles;
