import React, { useContext } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import useSystemLinksExt from "@micado-digital/react-ui/hooks/useSystemLinksExt";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Room.styles";

const Attributes = loadable(() =>
	import("@micado-digital/react-tourism-product/views/Detail01/Attributes")
);
const Button = loadable(() => import("@material-ui/core/Button"));
const Buttons = loadable(() =>
	import("@micado-digital/react-tourism-product/views/Detail01/Buttons")
);
const Container = loadable(() => import("@Components/views/Container"));
const Description = loadable(() =>
	import("@micado-digital/react-tourism-product/views/Detail01/Description")
);
const Detail = loadable(() => import("@micado-digital/react-tourism-product/ProductDetail"));
const Facts = loadable(() =>
	import("@micado-digital/react-tourism-product/views/Detail01/Facts")
);
const Gallery = loadable(() => import("./Gallery"));
// const Rates = loadable(() =>
// 	import("@micado-digital/react-tourism-product/views/Detail01/Rates")
// );
const Title = loadable(() =>
	import("@micado-digital/react-tourism-product/views/Detail01/Title")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const Room = ({ detailURL, fetchData }) => {
	const [pageData] = useContext(PageContext);
	const { lang, setupDetails: data } = pageData;
	const sysLink = useSystemLinksExt();
	const css = styles();
	const navigate = useNavigate();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const bookingParams = {
		adults: "",
		lang: "skd-language-code",
		room: "skd-offer-room"
	};

	const bookingButton = (
		<Button
			color="primary"
			variant="text"
			startIcon={<ReactSVGIcon src="/img/icons/calendar.svg" size={24} />}
		>
			{sysLink("booking")?.title}
		</Button>
	);

	const requestButton = (
		<Button
			color="primary"
			variant="text"
			startIcon={<ReactSVGIcon src="/img/icons/request.svg" size={24} />}
		>
			{sysLink("enquiry")?.title}
		</Button>
	);

	return (
		<div className="mco-product-detail">
			<Detail
				data={data}
				detailURL={detailURL}
				fetchData={fetchData}
				lang={lang}
				pagename={window.location.pathname}
			>
				<div className={clsx(css.elements, "mco-elements")}>
					<Container maxWidth="md">
						<Title />
					</Container>
					<Container maxWidth="md">
						<Facts />
					</Container>
					<Container maxWidth="md">
						<Description />
					</Container>
					<Gallery />
					<Container className={css.facts} maxWidth={false}>
						<Container maxWidth="md">
							<Attributes />
						</Container>
					</Container>
					{/* <Container maxWidth="md">
						<Rates />
					</Container> */}
					<Container className={css.buttonWrapper} maxWidth={false}>
						<Button
							color="primary"
							onClick={() => navigate(-1)}
							startIcon={<ReactSVGIcon src="/img/icons/chevron-left.svg" size={24} />}
							variant="contained"
							size="large"
						>
							<Local identifier="room.backToOverview" l={l} />
						</Button>
					</Container>
				</div>
				<Buttons
					bookingButton={bookingButton}
					bookingLink={sysLink("booking")?.url}
					bookingParams={bookingParams}
					requestButton={requestButton}
					requestLink={sysLink("enquiry")?.url}
				/>
			</Detail>
		</div>
	);
};

export default Room;
