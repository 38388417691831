import React from "react";
import clsx from "clsx";

import styles from "./Container.styles";

const Container = props => {
	const css = styles(props);
	const { anchor, children, className, style, tag, variant } = props;
	const { tag: anchorTag } = anchor || {};

	return (
		<div
			className={clsx(
				css.container,
				"mco-view-component-container",
				className,
				tag ? `mco-view-component-container-${tag}` : "",
				variant
					? `mco-view-component-container-${tag}${variant ? `--` + variant.toString() : ``}`
					: ""
			)}
			style={style}
			data-anchor={anchorTag}
			id={anchorTag}
		>
			{children}
		</div>
	);
};

export default Container;
