import loadable from "@loadable/component";

const Button = loadable(() => import("@material-ui/core/Button"));
const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactImageText01 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText01")
);
const ReactGallery04 = loadable(() => import("@micado-digital/react-gallery/ReactGallery04"));
const ReactSingleTeaser01 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser01")
);
const ReactTeaserList01 = loadable(() => import("../Elements/ReactTeaserList01"));
const ReactTeaser01 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser01"));
const CustomButton = loadable(() => import("@Elements/Button"));
const BackgroundImage = loadable(() => import("@Elements/BackgroundImage"));
const IconTeaser = loadable(() => import("@Elements/Teaser/IconTeaser"));
const Reviews = loadable(() => import("@Elements/Reviews"));
const ProductList = loadable(() =>
	import("@micado-digital/react-tourism-product/views/List01")
);
const Facts = loadable(() => import("@Elements/Facts"));
const Masonry = loadable(() => import("@Elements/Masonry"));
const TeaserRoom = loadable(() => import("@Elements/Teaser/Room"));

const getJSXElement = (element, l, lang, sysLink, isMobile) => {
	const { REACT_APP_PATH } = process.env;
	const {
		addition,
		addition2,
		anchor,
		children,
		elements,
		id,
		media,
		option,
		option2,
		reference,
		tag,
		teaser,
		text,
		textcontent,
		teasergroup,
		title,
		variant
	} = element;

	switch (tag) {
		case "basic-headline": {
			return (
				<Container
					key={id}
					tag={tag}
					variant={variant === 6 ? "overline" : (variant || 0) + 1}
					maxWidth="md"
					anchor={anchor}
				>
					<ReactHeadline01 text={text} variant={variant} />
				</Container>
			);
		}

		case "basic-text": {
			if (variant === 1) {
				return (
					<Container
						key={id}
						tag={tag}
						maxWidth="none"
						anchor={anchor}
						variant={variant === 1 ? "grey" : ""}
					>
						<Container maxWidth="md">
							<ReactText01 text={text} />
						</Container>
					</Container>
				);
			}

			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor}>
					<ReactText01 text={text} />
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="md"
					anchor={anchor}
					variant={variant === 0 ? "two-columns" : ""}
				>
					<ReactList01
						headlineVariant="h2"
						icon={<ReactSVGIcon src="/img/icons/check.svg" color="primary" size={24} />}
						textcontent={textcontent}
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor}>
					<ReactImageText01
						addition2={addition2}
						alt={addition}
						headlineVariant="h2"
						option={option}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "basic-imagetext-mobile",
							sm: "basic-imagetext"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			if (variant === 1) {
				return (
					<Container key={id} maxWidth="md" tag={tag} anchor={anchor?.tag}>
						<Masonry elements={elements} isMobile={isMobile} lang={lang} />
					</Container>
				);
			}

			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor}>
					<ReactGallery04
						autoplay={true}
						delay={5000}
						elements={elements}
						mediaFormats={{
							xs: "basic-gallery-mobile",
							md: "basic-gallery-preview"
						}}
						navNext="/img/icons/chevron-right-big.svg"
						navPrev="/img/icons/chevron-left-big.svg"
						loop={true}
						overlayMediaFormats={{
							xs: "basic-gallery"
						}}
						speed={1000}
						showPagination={true}
						showNavigation={isMobile ? false : true}
						breakpoints={{
							0: {
								slidesPerView: 1,
								spaceBetween: 16
							},
							960: {
								slidesPerView: 1,
								spaceBetween: 24
							}
						}}
						// SwiperProps={{ centeredSlides: true, loopedSlides: 2 }}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<ReactTable01
						addition={addition}
						headlineVariant="h6"
						items={newTableArray}
						title={title}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor}>
					<ReactReferences01
						elements={elements}
						title={title}
						hasIcon={false}
						headlineVariant="h2"
					/>
				</Container>
			);
		}

		case "basic-video": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<ReactVideo02
						media={media[0]}
						mediaFormats={{ xs: "video-mobile", sm: "video" }}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<ReactHTML html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-dropdown": {
			return null;
		}

		case "basic-dropdown-group": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={anchor}
					variant={element?.children?.[0]?.variant === 1 ? "full-width" : ""}
				>
					<ReactDropDown01
						headlineVariant="h5"
						option={element?.children?.[0]?.option2}
						title={element?.children?.[0]?.title}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleteaser": {
			return (
				<Container
					key={id}
					maxWidth={variant === 2 ? false : "lg"}
					tag={tag}
					style={variant === 2 ? { padding: 0 } : null}
					anchor={anchor}
					className={variant === 2 ? "fullscreen" : variant === 1 ? "right-direction" : ""}
				>
					<ReactSingleTeaser01
						buttonColor="primary"
						buttonLabel={l("reactSingleTeaser01.button")}
						buttonVariant="text"
						headlineVariant={isMobile ? "h5" : "h4"}
						icon="/img/icons/chevron-right.svg"
						iconSize={24}
						teaser={teaser}
						media={teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: variant === 2 ? "basic-teaser" : "basic-teaser-sub-1"
						}}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const link = textcontent?.items?.find(item => item?.name === "valUrl")?.text;
			const media = elements?.find(item => item?.tag === "valImage")?.media;

			const teaser = {
				title: title,
				text: text,
				link: link,
				linkTarget: "_blank"
			};

			return (
				<Container key={id} tag={tag} anchor={anchor}>
					<ReactSingleTeaser01
						buttonLabel={l("reactSingleTeaser01.button")}
						buttonSize={isMobile ? "small" : "medium"}
						headlineVariant={isMobile ? "h4" : "h3"}
						icon="/img/icons/chevron-right.svg"
						iconSize={24}
						teaser={teaser}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: "basic-teaser"
						}}
					/>
				</Container>
			);
		}

		case "basic-singleimage": {
			return (
				<Container key={id} tag={tag} anchor={anchor} style={{ padding: 0 }}>
					<ReactSingleImage01
						addition={addition}
						option2={option2}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "basic-singleimage-mobile",
							md: "basic-singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						reference={reference}
						text={text}
						title={title}
					/>
				</Container>
			);
		}

		case "basic-form": {
			return (
				<Container key={id} maxWidth={false} tag={tag} anchor={anchor}>
					<Container maxWidth="md">
						<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST" key={id}>
							<Connector formElements={elements} formID={id} />
						</Form>
					</Container>
				</Container>
			);
		}

		case "basic-teaser": {
			if (variant === 1) {
				return (
					<Container key={id} tag={tag} maxWidth={false} anchor={anchor} variant="slider">
						<Container maxWidth="lg">
							<ReactTeaserList01
								headline={title}
								headlineVariant="h2"
								loop={false}
								navNext="/img/icons/arrow-right.svg"
								navPrev="/img/icons/arrow-left.svg"
								text={text}
								sliderBreakpoints={{
									0: {
										slidesPerView: 1.1,
										spaceBetween: 24
									},
									600: {
										slidesPerView: 1.8,
										spaceBetween: 24
									},
									760: {
										slidesPerView: 2.1,
										spaceBetween: 24
									},
									960: {
										slidesPerView: 3,
										spaceBetween: 16
									},
									1200: {
										slidesPerView: 3,
										spaceBetween: 24
									}
								}}
								showPagination={true}
								showNavigation={true}
								wrapNaviation={false}
								variant="slider"
								SwiperProps={{
									centerInsufficientSlides: true
								}}
							>
								{teasergroup?.items?.map(({ id, link, media, text, title, linkTarget }) => {
									return (
										<ReactTeaser01
											button="Details"
											buttonIcon="/img/icons/chevron-right.svg"
											buttonVariant="text"
											key={id}
											link={link}
											media={media ? media?.[0] : {}}
											mediaFormats={{
												xs: "basic-teaser-sub-2"
											}}
											mediaPath={REACT_APP_PATH}
											target={linkTarget}
											title={title ? title : ""}
											text={text}
										/>
									);
								})}
							</ReactTeaserList01>
						</Container>
					</Container>
				);
			}

			if (variant === 2) {
				return (
					<Container key={id} tag={tag} maxWidth={false} anchor={anchor} variant="slider-room">
						<Container maxWidth="lg">
							<ReactTeaserList01
								headline={title}
								headlineVariant="h2"
								loop={false}
								navNext="/img/icons/arrow-right.svg"
								navPrev="/img/icons/arrow-left.svg"
								text={text}
								sliderBreakpoints={{
									0: {
										slidesPerView: 1.1,
										spaceBetween: 24
									},
									600: {
										slidesPerView: 1.8,
										spaceBetween: 24
									},
									760: {
										slidesPerView: 2.1,
										spaceBetween: 24
									},
									960: {
										slidesPerView: 3,
										spaceBetween: 16
									},
									1200: {
										slidesPerView: 3,
										spaceBetween: 24
									}
								}}
								showPagination={true}
								showNavigation={true}
								wrapNaviation={false}
								variant="slider"
								SwiperProps={{
									centerInsufficientSlides: true
								}}
							>
								{teasergroup?.items?.map(
									({ addition, id, link, media, text, title, linkTarget }) => {
										return (
											<TeaserRoom
												addition={addition}
												button="Details"
												buttonVariant="outlined"
												key={id}
												link={link}
												media={media ? media?.[0] : {}}
												mediaFormats={{
													xs: "basic-teaser-sub-2"
												}}
												mediaPath={REACT_APP_PATH}
												target={linkTarget}
												title={title ? title : ""}
												text={text}
											/>
										);
									}
								)}
							</ReactTeaserList01>
						</Container>
					</Container>
				);
			}

			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<ReactTeaserList01
						headline={title}
						headlineVariant="h2"
						loop={false}
						navNext="/img/icons/arrow-right.svg"
						navPrev="/img/icons/arrow-left.svg"
						text={text}
						gridBreakpoints={{
							xs: 12,
							sm: 6
						}}
						sliderBreakpoints={{
							0: {
								slidesPerView: 1.1,
								spaceBetween: 24
							},
							600: {
								slidesPerView: 1.8,
								spaceBetween: 24
							},
							760: {
								slidesPerView: 2.1,
								spaceBetween: 24
							}
						}}
						spacing={4}
						showPagination={true}
						showNavigation={true}
						wrapNaviation={false}
						variant={isMobile ? "slider" : "default"}
					>
						{teasergroup?.items?.map(({ id, link, media, text, title, linkTarget }) => {
							return (
								<ReactTeaser01
									button={l("reactTeaser01.button")}
									buttonIcon="/img/icons/chevron-right.svg"
									key={id}
									link={link}
									media={media ? media?.[0] : {}}
									mediaFormats={{
										xs: "basic-teaser-mobile",
										sm: "basic-teaser-sub-2"
									}}
									mediaPath={REACT_APP_PATH}
									target={linkTarget}
									title={title ? title : ""}
									text={text}
								/>
							);
						})}
					</ReactTeaserList01>
				</Container>
			);
		}

		case "extension-button": {
			const label = textcontent?.items?.find(item => item?.name === "valLabel")?.text;
			const link = elements?.find(item => item?.tag === "valLink")?.reference?.pageName;
			const externalLink = textcontent?.items?.find(
				item => item?.name === "valExternalLink"
			)?.text;
			const target = textcontent?.items?.find(item => item?.name === "valTarget")?.text;

			if (!link && !externalLink) {
				return;
			}

			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor}>
					<CustomButton
						label={label}
						link={link ? link : externalLink}
						target={externalLink && target === "True" ? "_blank" : "_top"}
					/>
				</Container>
			);
		}

		case "extension-bg": {
			return (
				<Container key={id} maxWidth={false} tag={tag} anchor={anchor}>
					<BackgroundImage />
				</Container>
			);
		}

		case "extension-icon-teaser": {
			const headline = textcontent?.items?.find(item => item?.name === "valHeadline")?.text;

			return (
				<Container key={id} tag={tag} anchor={anchor}>
					<ReactTeaserList01
						headline={headline}
						headlineVariant="h2"
						loop={false}
						navNext="/img/icons/arrow-right.svg"
						navPrev="/img/icons/arrow-left.svg"
						gridBreakpoints={{
							md: 3
						}}
						sliderBreakpoints={{
							0: {
								slidesPerView: 1,
								spaceBetween: 24
							},
							600: {
								slidesPerView: 2,
								spaceBetween: 24
							}
						}}
						spacing={4}
						showPagination={true}
						showNavigation={true}
						wrapNaviation={false}
						variant={isMobile ? "slider" : "default"}
					>
						{elements?.map(item => {
							const title = item?.textcontent?.items?.find(
								item => item?.name === "valTitle"
							)?.text;
							const text = item?.textcontent?.items?.find(
								item => item?.name === "valText"
							)?.text;
							const highlight = item?.textcontent?.items?.find(
								item => item?.name === "valHighlight"
							)?.text;
							const link = item?.elements?.find(item => item?.tag === "valLink")?.reference
								?.pageName;
							const icon = item?.elements?.find(item => item?.tag === "valIcon")?.media?.[0]
								?.filename;

							return (
								<IconTeaser
									key={item?.id}
									buttonLabel={l("iconTeaser.button")}
									highlight={highlight === "True" ? true : false}
									title={title ? title : ""}
									text={text}
									link={link}
									icon={icon}
								/>
							);
						})}
					</ReactTeaserList01>
				</Container>
			);
		}

		case "tourism-room-list": {
			const bookingParams = {
				adults: "",
				lang: "skd-language-code",
				room: "skd-offer-room"
			};

			const bookingButton = (
				<Button
					color="primary"
					variant="outlined"
					startIcon={<ReactSVGIcon src="/img/icons/calendar.svg" size={24} />}
				>
					{l("tourism.buttonBooking")}
				</Button>
			);

			const detailButton = (
				<Button
					color="primary"
					variant="outlined"
					startIcon={<ReactSVGIcon src="/img/icons/room.svg" size={24} />}
				>
					Details
				</Button>
			);

			return (
				<Container key={id} tag={tag} maxWidth={false} anchor={anchor}>
					<Container maxWidth="lg">
						<ProductList
							bookingButton={bookingButton}
							bookingLink={sysLink("booking")}
							bookingParams={bookingParams}
							detailButton={detailButton}
							lang={lang}
							mediaFormats={{ xs: "product-teaser" }}
							providerID={1}
							showFilter={true}
							showAllRoomsFilter={false}
							showPersonsFilter={false}
						/>
					</Container>
				</Container>
			);
		}

		case "extension-reviews": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor} style={{ padding: 0 }}>
					<Reviews id={id} items={elements} />
				</Container>
			);
		}

		case "extension-facts": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor}>
					<Facts id={id} items={elements} />
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
