import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useScrollYPosition } from "react-use-scroll-position";
import Container from "@Components/views/Container";
import { Link } from "react-scroll";

import styles from "./AnchorMenu.styles";

const AnchorMenu = ({ items }) => {
	const [isVisible, setIsVisible] = useState(false);
	const scrollY = useScrollYPosition();
	const css = styles();

	useEffect(() => {
		if (scrollY > 90) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [scrollY]);

	return (
		<div
			className={clsx(css.root, "mco-view-component-anchormenu", {
				[css.rootVisible]: isVisible
			})}
		>
			<Container className={clsx(css.container, "mco-view-component-anchormenu__container")}>
				<div className={clsx(css.scroller, "mco-view-component-anchormenu__scroller")}>
					<div className={clsx(css.wrapper, "mco-view-component-anchormenu__wrapper")}>
						{items?.map((item, index) => {
							return (
								<Link
									className={clsx(css.item, "mco-view-component-anchormenu__item")}
									activeClass="active"
									key={index}
									to={item?.tag}
									offset={-160}
									spy={true}
									smooth={true}
									duration={500}
								>
									{item?.title}
								</Link>
							);
						})}
					</div>
				</div>
			</Container>
		</div>
	);
};

export default AnchorMenu;
