import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		background: theme.palette.primary.light,
		display: "flex",
		opacity: 0,
		position: "fixed",
		top: 90,
		transform: "translate(0, -100%)",
		transition: "transform 500ms ease, opacity 500ms ease",
		width: "100%",
		zIndex: 99,
		"&:after": {
			background: "linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%)",
			top: 0,
			content: "''",
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			right: 0,
			width: 60,
			[theme.breakpoints.up("sm")]: {
				display: "none"
			}
		},
		"&:before": {
			backgroundImage:
				'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzYiIGhlaWdodD0iODIiIHZpZXdCb3g9IjAgMCA3NiA4MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik00OS40MDY0IDQyLjA3MDlDNDEuNTg0NyAzNC4zMTYxIDMzLjk5MjkgMjYuMzk4MiAyNi4xODA5IDE4LjgyOTZDMjUuMjg4NiAxNy45NjUxIDI0LjUyNjcgMTYuNzM3NCAyNS4wNjggMTYuMTk2MUMyNS42NDM0IDE1LjYyMDcgMjYuNzgwMSAxNi40NTIgMjcuMjkzIDE2Ljg5MDhDMzAuNjk5NyAxOS44MDYgMzMuODQ2OSAyMy4wNDM5IDM3LjAyIDI2LjIwNTJDNDEuMzIxNCAzMC40OTA3IDQ1LjQ4NzIgMzQuOTE0NyA0OS44MDY2IDM5LjE4MDZDNTAuNDYwNSAzOS44MjY0IDUxLjA2NDQgNDAuNDIyOCA1MS41MzgxIDQxLjExNEM1Mi4wMzQzIDQxLjgzNzkgNTIuMjMxMSA0Mi40MzE5IDUxLjYxNTkgNDMuMDU2NUM0My45OTIgNTAuNzk2MiAzNi4yOTk0IDU4LjkyNjEgMjguMDM1OCA2NkMyOC4zMDI1IDY0LjA1NTYgNDIuMzY4OCA1MC4xMTcxIDQ5LjQwNjQgNDIuMDcwOVoiIGZpbGw9IiM5MzhFODciLz4NCjwvc3ZnPg0K")',
			backgroundSize: "contain",
			backgroundRepeat: "no-repeat",
			top: 8,
			content: "''",
			height: 34,
			position: "absolute",
			pointerEvents: "none",
			right: 8,
			width: 30,
			zIndex: 1,
			[theme.breakpoints.up("sm")]: {
				display: "none"
			}
		}
	},
	rootVisible: {
		opacity: 1,
		transform: "translate(0, 0)"
	},
	container: {
		display: "flex",
		overflow: "hidden",
		minHeight: 48,
		padding: theme.spacing(0, 1),
		WebkitOverflowScrolling: "touch"
	},
	scroller: {
		display: "inline-block",
		flex: "1 1 auto",
		marginBottom: 0,
		overflowX: "scroll",
		position: "relative",
		scrollbarWidth: "none",
		whiteSpace: "nowrap",
		"&::-webkit-scrollbar": {
			display: "none"
		}
	},
	wrapper: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		gap: theme.spacing(4),
		[theme.breakpoints.up("md")]: {
			justifyContent: "center"
		}
	},
	item: {
		color: theme.palette.text.primary,
		cursor: "pointer",
		fontFamily: "Josefin Sans",
		fontSize: 14,
		fontWeight: 400,
		letterSpacing: "0.01em",
		opacity: 0.7,
		padding: theme.spacing(1, 1.5),
		position: "relative",
		textTransform: "uppercase",
		transition: theme.transitions.create(["opacity"], {
			duration: theme.transitions.duration.short
		}),
		"&.active": {
			opacity: 1
		},
		"&:hover": {
			opacity: 1
		},
		"&:after": {
			background: theme.palette.grey["A200"],
			content: "''",
			height: 16,
			marginTop: -8,
			position: "absolute",
			right: -16,
			top: "50%",
			width: 1
		},
		"&:last-child:after": {
			display: "none"
		}
	}
}));

export default styles;
